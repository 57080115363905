import React from "react";
import { HOME_PAGE_ACTION } from "../pages/home_page/constant/Constant";

const initialState = {
  tour_data: [],
  selected_tour: [],
  // image_path: "http://192.168.2.217:8000",
  pdf_path: "http://192.168.2.252:8000",
  // pdf_path: "http://beta.api.cozytravelph.com/",

  requested_data: [],
  country_list: [],
  selected_country: "",

  person: {
    name: "",
    age: "",
    siblings: [
      {
        sibling_info: {
          name: "",
          age: "",
          gender: "",
        },
      },
    ],
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
