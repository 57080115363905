// import logo from "./logo.svg";
// import "./App.css";
import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

const IndexHomePage = lazy(() => import("./pages/home_page/IndexHomePage"));
const All_Packages = lazy(() => import("./pages/all_packages/All_Packages"));
const Package_Details = lazy(() =>
  import("./pages/package_details/Package_Details")
);

const NavigationBar = lazy(() =>
  import("./components/navigation/NavigationBar")
);
const Destinations = lazy(() => import("./pages/destinations/Destiantion"));
const Visa = lazy(() => import("./pages/visa/VisaApplication"));

function App() {
  return (
    <HashRouter>
      <Suspense>
        <Routes>
          <Route path="/" element={<NavigationBar />}>
            <Route path="/" element={<IndexHomePage />} />
            <Route path="/all_packages" element={<All_Packages />} />
            <Route path="/package_details" element={<Package_Details />} />
            <Route path="/destinations" element={<Destinations />} />
            <Route path="/visa" element={<Visa />} />
          </Route>
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
